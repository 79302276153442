<template>
  <div class="box">
    <div v-if="ifChech" class="title_boxs">
      <img class="titleTip" src="@/assets/img/partner/titleTip.png" alt="" @click="ifChech = false" />
      <div class="kcName">返回消息</div>

    </div>
    <div v-if="!ifChech" class="newspon dis_flex_start" style="align-items: center" @click="allReader()">
      <img style="width: 14px; height: 13px; margin-right: 4px" src="@/assets/img/partner/allread.png" alt="" />
      <div class="newspontil">一键已读</div>
    </div>
    <el-tabs v-if="!ifChech" v-model="activeName">
      <el-tab-pane label="全部消息" name="first">

      </el-tab-pane>
    </el-tabs>
    <div class="mineNews">
      <!-- <div class="mantil">我的消息</div> -->
      <!--  消息通知 -->
      <div v-if="!ifChech" class="new">
        <!-- 缩宽90% -->
        <div class="news">
          <!-- 定位 -->
          <!-- <div class="tops" /> -->
          <!-- 跳转盒子 -->
          <!-- 跳转内容 formName  1 课程安排通知-->
          <div class="allnew">
            <!-- 模块1 -->
            <div v-for="(item, index) in list" :key="index" class="allnews" @click="xaoxixq(item)">
              <div class="tils">
                <div class="dis_flex" style="align-items: center">
                  <img v-if="item.isRead != 1" src="~@/assets/img/partner/read.png" alt="" />
                  <img v-else src="~@/assets/img/partner/noread.png" alt="" />
                  <div class="til">{{ item.title }}</div>
                  <!-- <div v-if="item.isRead == 1" class="red" /> -->
                </div>
                <div class="new_tim">{{ item.addTime }}</div>
              </div>
            </div>
            <div v-if="!list || list.length == 0" class="nothing">
              <div class="nothing-text">暂无消息哦~</div>
            </div>
            <!-- 分页 -->
            <el-pagination class="pagination colleges-pagination" :total="total" :current-page="pageNum"
              :page-sizes="[8, 16, 24, 40]" :page-size="pageSize" background layout="total, sizes, prev, pager, next"
              @current-change="handleCurrentChange" @size-change="handleSizeChange" />
          </div>
        </div>
      </div>

      <!-- 右侧内容 a=5 消息通知详情 -->
      <div v-if="ifChech" class="newxx">
        <!-- <div class="pointer goBack" @click="ifChech = false">返回</div> -->
        <!-- <el-button size="mini m-l-20" @click="ifChech = false">返回</el-button> -->
        <div class="newxxTop">{{ detail.title }}</div>
        <!-- <div class="xximg"><img src="@/assets/img/Home/banner_02.jpg"  /></div> -->
        <div class="newxxCon">
          <div v-html="ImgSizeLimit(detail.content)" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getInformList } from "@/api/user";
import { Know } from "@/api/know";
const know = new Know();
import Bus from "@/api/bus";
import Vue from "vue";
export default {
  components: {},
  data() {
    return {
      userId: null, // 用户信息
      formName: "课程安排通知",
      type: ["课程安排通知", "考前督学通知", "考试前提示通知"],
      list: [],
      pageSize: 8,
      activeName: "first",
      pageNum: 1,
      total: 0,
      // 是否显示详情
      ifChech: false,
      detail: {}, // 详情内容
    };
  },
  created() {
    this.userId = localStorage.getItem("userId");
    this.getList();
  },
  methods: {
    /* 获取列表 */
    getList() {
      getInformList(this.userId, "", this.pageSize, this.pageNum).then(
        (res) => {
          this.list = res.msg.rows;
          this.total = res.msg.total;
        }
      );
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.getList();
    },
    /* ------------操作wx.canIUse(); */
    typeChange(val) {
      this.formName = val;
      this.getList();
    },
    // 查看详情
    xaoxixq(item) {
      this.detail = item;
      this.ifChech = true;
      this.read(item.id);
    },
    // 一键已读
    allReader() {
      know.readAllInforms().then((res) => {
        this.getList();
        Bus.$emit("readChane");
      });
    },
    // 修改已读状态
    read(ids) {
      know.readInforms(ids).then((res) => {
        this.getList();
        Bus.$emit("readChane");
      });
    },
  },
};
</script>
<style lang="less" scoped>
.box {
  position: relative;

  .newspon {
    position: absolute;
    right: 0px;
    top: 5px;
    z-index: 666;
    // width: 110px;
    height: 34px;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular;
    font-weight: 400;
    color: #4a6af0;
    cursor: pointer;
    line-height: 37px;

    .newspontil {
      width: 56px;
      height: 14px;
      font-size: 14px;
      font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      font-weight: 400;
      color: #0270e8;
      line-height: 14px;
    }
  }

  .mineNews {
    width: 100%;

    /*    height: 100%; */
    // 跳转盒子
    .basicsName {
      width: 100%;
      height: 37px;
      margin-top: 20px;
      display: flex;
      align-items: center;
      line-height: 37px;
      margin-bottom: 20px;

      .basicsNames {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        margin-right: 28px;

        // margin-top: 40px;
        .basicsNamesTop {
          font-size: 16px;
          font-weight: 400;
          cursor: pointer;

          font-size: 16px;
          font-family: Microsoft YaHei-Regular;
          color: #333333;
          line-height: 37px;
        }

        .basicsNamesTops {
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #333333;
        }

        .basicsNamesCen {
          margin-top: 14px;
          width: 59px;
          height: 2px;
          background: #ef0d0d;
          border-radius: 1p;
        }
      }
    }

    // 小盒子2右侧  a=4 消息通知
    .new {
      width: 100%;
      // height: 736px;
      min-height: 736px;
      padding-bottom: 20px;
      background-color: #ffffff;
      position: relative;

      // 缩宽
      .news {
        // width: 90%;
        height: 100%;
        margin: auto;
        position: relative;

        .tops {
          width: 100%;
          height: 1px;
        }

        // 定位

        // 标题
        p {
          width: 100%;
          height: 50px;
          display: flex;
          justify-content: flex-start;
          align-items: flex-end;
          font-size: 22px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #27323f;
        }

        // 跳转盒子

        // 跳转内容 formName = 1 全部通知
        .allnew {
          width: 100%;
          height: auto;
          // border-top: 1px solid #eeeeee;

          .allnews {
            width: 100%;
            // border-bottom: 1px solid #eeeeee;
            border-radius: 4px;
            display: flex;
            flex-direction: column;
            position: relative;

            .tils {
              width: 994px;
              height: 90px;
              background: #ffffff;
              box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.06);
              border-radius: 6px 6px 6px 6px;
              opacity: 1;
              padding: 18px 20px;
              margin-bottom: 20px;
              // align-items: center;
              // display: flex;
              // justify-content: space-between;
              cursor: pointer;

              img {
                width: 20px;
                margin-right: 10px;
              }

              .red {
                // position: absolute;
                // top: 35px;
                // left: -20px;
                margin-left: 15px;
                width: 8px;
                height: 8px;
                background: #ff4027;
                border-radius: 4px;
              }

              .til {
                height: 16px;
                font-size: 16px;
                font-family: Source Han Sans CN-Medium, Source Han Sans CN;
                font-weight: 500;
                color: #333333;
                line-height: 16px;
              }

              .new_tim {
                height: 14px;
                font-size: 14px;
                font-family: Source Han Sans CN-Normal, Source Han Sans CN;
                font-weight: 400;
                color: #999999;
                line-height: 14px;
                margin-top: 20px;
              }
            }

            .new_con {
              margin-top: 20px;
            }

            .allnewsLeft {
              width: 50px;
              height: 100%;
              margin: 0px 20px 0px 30px;

              img {
                width: 50px;
                height: 50px;
                margin-top: 20px;
                cursor: pointer;
              }
            }

            .allnewsRight {
              width: 820px;
              height: 100%;
              cursor: pointer;

              div {
                width: 100%;
                height: 39px;
                font-size: 16px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #333333;
                margin: 23px 0px 15px 0px;
              }

              span {
                font-size: 12px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #999999;
              }
            }
          }
        }
      }
    }

    .newxx {
      width: 100%;
      height: 736px;
      background-color: #ffffff;
      position: relative;
      overflow-y: scroll;
      padding: 20px 0px;

      .newxxTop {
        width: 940px;
        height: 77px;
        border-bottom: 1px solid #eeeeee;
        margin: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 22px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #27323f;
      }

      .xximg {
        width: 474px;
        height: 267px;
        margin: 22px auto;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .newxxCon {
        width: 890px;
        height: 98px;
        margin: auto;
        font-size: 22px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;

        /deep/img {
          max-width: 100% !important;
          max-height: 100% !important;
        }
      }
    }

    .goBack {
      position: absolute;
      left: 20px;
      // top: 20px;
      line-height: 77px;
    }
  }
}

.mantil {
  height: 104px;
  line-height: 104px;
  font-size: 24px;
  font-family: Microsoft YaHei-Bold;
  font-weight: bold;
  color: #333333;
  text-align: left;
}

.title_boxs {
  position: relative;
  // width: 1050px;
  height: 50px;
  // background: #f5f6f7;
  border-radius: 6px 6px 6px 6px;
  opacity: 1;
  display: flex;
  // padding: 0 20px;
  align-items: center;
  justify-content: flex-start;

  .titleTip {
    width: 26px;
    margin-right: 16px;
    cursor: pointer;
  }

  .kcName {
    height: 24px;
    font-size: 16px;
    font-family: Source Han Sans CN-Bold, Source Han Sans CN;
    font-weight: bold;
    color: #333333;
    line-height: 24px;
  }

  .item-validPeriod {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    top: 0px;
    right: 0px;
    width: 166px;
    height: 28px;
    background: #e2eeff;
    opacity: 1;
    font-size: 14px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: #458bd8;
  }
}
</style>
